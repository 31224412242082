<template>
  <div class="wrapper">
    <div class="info">
      <div class="title">
        CODING FOR HEALTH
      </div>
      <div class="description">
        <b>Who are we?</b><br/>
        We're a dynamic team dedicated to crafting mobile applications, specializing in the realms of healthcare and utilities. Our commitment to cutting-edge solutions positions us as problem-solvers at the intersection of technology and user needs.<br/>
        <b>What are we doing?</b><br/>
        Crafting modern mobile applications, we focus on enhancing health and daily living. Our mission is to deliver innovative solutions, addressing challenges and fostering positive change in the realms of medicine and utilities.<br/>
        <b>Our goal</b><br/>
        Our goal is to serve as a catalyst for positive transformations. By creating innovative mobile applications, we aspire to empower users to overcome obstacles and enrich their lives. We believe that everyone, through their work, has the potential to make the world a better place.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "temp",
}
</script>
<style lang="less">
@import "style.less";
</style>